import React, { Component } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Hader";
import emailjs from "emailjs-com";
import { TEMPLATE_ID, USER_ID } from "../email";
import WAFloating from "../Components/Common/WhatsAppBtn/FloatingBtn";

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs.sendForm(`service_n7s41nj`, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        alert("Message Sent, We will get back to you shortly", result.text);
      },
      (error) => {
        alert("An error occurred, Please try again", error.text);
      }
    );
  };
  return (
    <div>
      <Header />

      {/* <!-- Page Content --> */}
      <div class="page-heading-contact header-text">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1>Get In Touch</h1>
              <span style={{ color: "#66B032" }}>
                “Please feel free to contact us for any inquiry”
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-information">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="contact-item">
                <i class="fa fa-phone"></i>
                <h4>Phone</h4>
                {/* <p>Vivamus ut tellus mi. Nulla nec cursus elit, id vulputate nec cursus augue.</p> */}
                <a href="#">
                  +94 81 220 8108
                  {/* <br></br>+94 70 170 0300 */}
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="contact-item">
                <i class="fa fa-envelope"></i>
                <h4>Email</h4>
                {/* <p>Vivamus ut tellus mi. Nulla nec cursus elit, id vulputate nec cursus augue.</p> */}
                <a href="#">
                  info@kandyanenergy.com <br></br>support@kandyanenergy.com
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="contact-item">
                <i class="fa fa-map-marker"></i>
                <h4>Location</h4>
                {/* <p>1020 New Mountain Street<br />Forest Park, FP 11220</p> */}
                <a href="#">No 645, William Gopallawa Mawatha, Kandy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div id="map">
        <iframe
          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Kandyan%20Energy&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          width="100%"
          height="800px"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>

      <div class="callback-form ">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-heading">
                <h2>
                  {" "}
                  <em>
                    Don’t Hesitate To <br></br>Contact Us
                  </em>
                </h2>
              </div>
            </div>
            <div class="col-md-12">
              <div class="contact-form">
                <form
                  id="contact"
                  action=""
                  method="get"
                  onSubmit={handleSubmit}
                >
                  <div class="row">
                    <div class="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="name"
                          type="text"
                          class="form-control"
                          id="name"
                          placeholder="Full Name"
                          required=""
                        />
                      </fieldset>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="email"
                          type="text"
                          class="form-control"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          placeholder="E-Mail Address"
                          required=""
                        />
                      </fieldset>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12">
                      <fieldset>
                        <input
                          name="subject"
                          type="text"
                          class="form-control"
                          id="subject"
                          placeholder="Subject"
                          required=""
                        />
                      </fieldset>
                    </div>
                    <div class="col-lg-12">
                      <fieldset>
                        <textarea
                          name="message"
                          rows="6"
                          class="form-control"
                          id="message"
                          placeholder="Your Message"
                          required=""
                        ></textarea>
                      </fieldset>
                    </div>
                    <div class="col-lg-12">
                      <fieldset>
                        <button
                          type="submit"
                          id="form-submit"
                          class="border-button"
                        >
                          Send Message
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <br />
      <br />
      <iframe
        src="https://cdn.lightwidget.com/widgets/0c16f19afb0c56468bd72cbb47f42497.html"
        scrolling="no"
        allowTransparency="true"
        className="lightwidget-widget"
        style={{ width: "100%", border: 0, overflow: "hidden" }}
      />
      <WAFloating />
      <Footer />
    </div>
  );
};
export default Contact;
