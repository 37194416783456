import React, { Component } from "react";
import Slider from "react-slick";
import ABB from "../assets/logos/abb.png";
import JA from "../assets/logos/ja.png";
import SOLARX from "../assets/logos/solax.png";
import SOLIS from "../assets/logos/solis.png";
import JINKO from "../assets/logos/jinko.png";
import GROWATT from "../assets/logos/growatt.png";
import SMART_POWER from "../assets/logos/smart_power.png";

export default class LoginSlider extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          <div>
            <img src={ABB} className="logo-slider" />
          </div>
          <div>
            <img src={JA} className="logo-slider" />
          </div>
          <div>
            <img src={SOLARX} className="logo-slider" />
          </div>
          <div>
            <img src={JINKO} className="logo-slider" />
          </div>
          <div>
            <img src={SOLIS} className="logo-slider" />
          </div>
          <div>
            <img src={GROWATT} className="logo-slider" />
          </div>
          <div>
            <img src={SMART_POWER} className="logo-slider" style={{width:"auto", height: 120}} />
          </div>
        </Slider>
      </div>
    );
  }
}
