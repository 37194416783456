const MapModal = (props) => {
  return (
    <div class="more-info">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="more-info-content-home">
              <div class="row">
                <div class="col-md-8 align-self-center">
                  <div class="right-content">
                    {/* <h6 style={{ color: "#fff" }}>WHO ARE WE </h6> */}
                    <h2>
                      {" "}
                      <em style={{ color: "#66b032", letterSpacing: 2 }}>
                        Live Demostration
                      </em>
                    </h2>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: 25,
                        letterSpacing: 2,
                      }}
                    >
                      Currently under construction
                    </p>
                    {/* <Link to="/Contact" class="filled-button">
                        contact us
                      </Link> */}
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="left-image-home" style={{ marginTop: -60 }}>
                    <img src="assets/images/green-lanka.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapModal;
