import {axiosInstance} from './AxiosInstance';

const API_URL="/api/districts";

const getAllDistrictsData = async() => {

    try{
      const response = await axiosInstance.get(API_URL + "/allDistricts");
       console.log(response.data);
       return response.data;

    
  
    }catch(err){
      throw err;
    }
  
  
      
  };

  export default {getAllDistrictsData};

