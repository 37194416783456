import React from "react";
import "./FloatingStyle.css";

function WAFloating() {
  return (
    <div class="floating_btn">
      <a target="_blank" href="https://api.whatsapp.com/send?phone=94707700890">
        <div class="contact_icon">
          <i class="fa fa-whatsapp my-float"></i>
        </div>
      </a>
      <p class="text_icon">Let's talk?</p>
    </div>
  );
}

export default WAFloating;
