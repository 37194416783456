import React from "react";
import spinner from "./enery_solars.gif";

function Spinner(props) {
  return (
    <div
      style={{
        width: "450px",
        height: "300px",
        top: "40%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        backgroundColor: "rgb(102, 176, 50)",
        borderRadius: "15px",
        transition: "all",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // flex: 1,
      }}
    >
      {/* {props.show ? ( */}
      <img
        src={props.show ? spinner : null}
        style={{
          width: "100px",
          // display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
        // alt="Loading..."
      />
      {/* ) : null} */}

      <p
        style={{
          fontSize: "15pt",
          color: "white",
          // marginTop: "70px",
          width: "330px",
          alignItems: "center",
          justifyContent: "center",
          // marginLeft: "100px",
          margin: "auto",
        }}
      >
        {props.text}
      </p>
    </div>
  );
}

export default Spinner;
