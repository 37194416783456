import React, { Component, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { OnModalContext } from "../context";
import kESLOGO from "../assets/images/logo-new-white.png";

const Header = () => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     currentDateTime: Date().toLocaleString(),
  //   };
  // }

  const { onModal, changeModalOpenValue, changeModalClick, changeHomeFlag } =
    useContext(OnModalContext);

  return (
    <div>
      <div class="sub-header">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-xs-12">
              <ul class="left-info">
                <li>
                  <a href="#">
                    <i class="fa fa-map-marker"></i>No 645, William Gopallawa
                    Mawatha, Kandy
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    info@kandyanenergy.com
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <ul class="right-icons">
                <li>
                  <a href="http://www.facebook.com/kandyanenergy">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="http://instagram.com/kandyanenergy">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <header class="">
        <nav class="navbar navbar-expand-lg">
          <div class="container">
            <div class="navbar-brand">
              <img
                src="https://user-images.githubusercontent.com/66427003/194798638-bfb5bcee-37f0-4e24-a1d0-6077c92bfb13.png"
                className="kandy-logo"
                alt="KES Logo"
              />
              <Link class="nav-link" to="/" />
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <Link class="nav-link " to="/">
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/About" class="nav-link">
                    Company
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/Services" class="nav-link">
                    Solar Systems
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/Contact" class="nav-link">
                    Contact Us
                  </Link>
                </li>
                <li class="nav-item">
                  <Link onClick={changeModalOpenValue}>
                    <div class="nav-link-livedemo">Live Demonstaration</div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};
export default Header;
