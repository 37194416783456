import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Service";
import Contact from "./Pages/Contact";
import { useState } from "react";
import { OnModalContext } from "./context";

import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Suspense } from "react/cjs/react.production.min";
// const Home = lazy(() => import("./Pages/Home"));
// const About = lazy(() => import("./Pages/About"));
// const Services = lazy(() => import("./Pages/Service"));
// const Contact = lazy(() => import("./Pages/Contact"));

function App() {
  const [onModal, setOnModal] = useState(false);
  const [homeFlag, setHomeFlag] = useState(false);

  const changeModalOpenValue = () => {
    // if (onModal === false) {
    // setOnModal(true);
    // }
    setOnModal(!onModal);

    // document.addEventListener("click", setOnModal(!onModal));
  };

  const changeModalClick = () => {
    if (onModal) {
      document.addEventListener("click", setOnModal(false));
    }
  };

  const changeHomeFlag = () => {
    // setHomeFlag(!homeFlag);
    window.location.reload();
  };
  return (
    <OnModalContext.Provider
      value={{
        onModal,
        changeModalOpenValue,
        changeModalClick,
        homeFlag,
        changeHomeFlag,
      }}
    >
      <Router>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/About" component={About} />
          <Route path="/Services" component={Services} />
          <Route path="/Contact" component={Contact} />
        </Switch>
        {/* </Suspense> */}
      </Router>
    </OnModalContext.Provider>
  );
}

export default App;
