import React, { Component } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Hader";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import PowerPlanService from "../Services/PowerPlanService";
import CommonService from "../Services/CommonService";
import Spinner from "../Components/Common/Spinner";
import Modal from "react-modal";
import WAFloating from "../Components/Common/WhatsAppBtn/FloatingBtn";

const DistrctsList = [
  {
    id: 1,
    province_id: 6,
    name_en: "Ampara",
    name_si: "අම්පාර",
    name_ta: "அம்பாறை",
  },
  {
    id: 2,
    province_id: 8,
    name_en: "Anuradhapura",
    name_si: "අනුරාධපුරය",
    name_ta: "அனுராதபுரம்",
  },
  {
    id: 3,
    province_id: 7,
    name_en: "Badulla",
    name_si: "බදුල්ල",
    name_ta: "பதுளை",
  },
  {
    id: 4,
    province_id: 6,
    name_en: "Batticaloa",
    name_si: "මඩකලපුව",
    name_ta: "மட்டக்களப்பு",
  },
  {
    id: 5,
    province_id: 1,
    name_en: "Colombo",
    name_si: "කොළඹ",
    name_ta: "கொழும்பு",
  },
  {
    id: 6,
    province_id: 3,
    name_en: "Galle",
    name_si: "ගාල්ල",
    name_ta: "காலி",
  },
  {
    id: 7,
    province_id: 1,
    name_en: "Gampaha",
    name_si: "ගම්පහ",
    name_ta: "கம்பஹா",
  },
  {
    id: 8,
    province_id: 3,
    name_en: "Hambantota",
    name_si: "හම්බන්තොට",
    name_ta: "அம்பாந்தோட்டை",
  },
  {
    id: 9,
    province_id: 9,
    name_en: "Jaffna",
    name_si: "යාපනය",
    name_ta: "யாழ்ப்பாணம்",
  },
  {
    id: 10,
    province_id: 1,
    name_en: "Kalutara",
    name_si: "කළුතර",
    name_ta: "களுத்துறை",
  },
  {
    id: 11,
    province_id: 2,
    name_en: "Kandy",
    name_si: "මහනුවර",
    name_ta: "கண்டி",
  },
  {
    id: 12,
    province_id: 5,
    name_en: "Kegalle",
    name_si: "කෑගල්ල",
    name_ta: "கேகாலை",
  },
  {
    id: 13,
    province_id: 9,
    name_en: "Kilinochchi",
    name_si: "කිලිනොච්චිය",
    name_ta: "கிளிநொச்சி",
  },
  {
    id: 14,
    province_id: 4,
    name_en: "Kurunegala",
    name_si: "කුරුණෑගල",
    name_ta: "குருணாகல்",
  },
  {
    id: 15,
    province_id: 9,
    name_en: "Mannar",
    name_si: "මන්නාරම",
    name_ta: "மன்னார்",
  },
  {
    id: 16,
    province_id: 2,
    name_en: "Matale",
    name_si: "මාතලේ",
    name_ta: "மாத்தளை",
  },
  {
    id: 17,
    province_id: 3,
    name_en: "Matara",
    name_si: "මාතර",
    name_ta: "மாத்தறை",
  },
  {
    id: 18,
    province_id: 7,
    name_en: "Monaragala",
    name_si: "මොණරාගල",
    name_ta: "மொணராகலை",
  },
  {
    id: 19,
    province_id: 9,
    name_en: "Mullaitivu",
    name_si: "මුලතිව්",
    name_ta: "முல்லைத்தீவு",
  },
  {
    id: 20,
    province_id: 2,
    name_en: "Nuwara Eliya",
    name_si: "නුවර එළිය",
    name_ta: "நுவரேலியா",
  },
  {
    id: 21,
    province_id: 8,
    name_en: "Polonnaruwa",
    name_si: "පොළොන්නරුව",
    name_ta: "பொலன்னறுவை",
  },
  {
    id: 22,
    province_id: 4,
    name_en: "Puttalam",
    name_si: "පුත්තලම",
    name_ta: "புத்தளம்",
  },
  {
    id: 23,
    province_id: 5,
    name_en: "Ratnapura",
    name_si: "රත්නපුර",
    name_ta: "இரத்தினபுரி",
  },
  {
    id: 24,
    province_id: 6,
    name_en: "Trincomalee",
    name_si: "ත්‍රිකුණාමලය",
    name_ta: "திருகோணமலை",
  },
  {
    id: 25,
    province_id: 9,
    name_en: "Vavuniya",
    name_si: "වව්නියාව",
    name_ta: "வவுனியா",
  },
];

let resMsg = "";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

class Services extends React.Component {
  constructor() {
    super();
    this.state = {
      tab1Value: true,
      tab2Value: false,
      tab3Value: false,
      fNamae: "",
      lName: "",
      email: "",
      mobile: "",
      regNo: "",
      noOfUnits: null,
      requestChoice: null,
      tarrif: null,
      selfGeneration: null,
      selfGenerationMethod: null,
      districts: [],
      district: null,
      serviceProvider: "",
      monthlyBill: null,
      loading: false,
      isOpen: false,
      messsage: "",
    };
  }

  componentDidMount() {
    // this.GettAllDistricts();
    // if(this.state.districts.length>0){
    //   console.log(this.state.districts);
    // }
    // window.location.replace("/#solar-form");
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: true });
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  };

  GettAllDistricts = () => {
    const res = CommonService.getAllDistrictsData();
    this.setState({
      districts: res,
    });
    setTimeout(() => {
      console.log("Districts Data: ", this.state.districts);
    }, 1000);

    // setTimeout(()=>{
    //   this.setState({
    //     districts:res
    //   }+  console.log("Districts Dta: ",this.state.districts))
    // },3000)
  };

  pressTab1 = () => {
    this.setState({
      tab1Value: true,
      tab2Value: false,
      tab3Value: false,
    });
  };

  pressTab2 = () => {
    this.setState({
      tab1Value: false,
      tab2Value: true,
      tab3Value: false,
    });
  };

  pressTab3 = () => {
    this.setState({
      tab1Value: false,
      tab2Value: false,
      tab3Value: true,
    });
  };

  onSubmittRequest = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const data = {
      fName: this.state.fNamae,
      lName: this.state.lName,
      email: this.state.email,
      mobile: this.state.mobile,
      serviceProvider: this.state.serviceProvider,
      regNo: this.state.regNo,
      monthlyBill: this.state.monthlyBill,
      noOfUnits: this.state.noOfUnits,
      districtID: this.state.district,
      requestChoice: this.state.requestChoice,
      tarrif: this.state.tarrif,
      selfGeneration: this.state.selfGeneration,
    };

    PowerPlanService.resedentialPowerPlanRequest(data).then(
      (data) => {
        console.log(data);
        this.setState({ loading: false });
        // alert(
        //   "We've sent you an email with the quotation, One of our sales representative will be contacting you shortly"
        // );
        this.setState({ isOpen: true });
        console.log("Message: ", data.message);
        this.setState({ message: data.message });
        resMsg = data.message;
        this.setState({
          fNamae: "",
          lName: "",
          email: "",
          mobile: "",
          regNo: "",
          noOfUnits: 0,
          requestChoice: "",
          tarrif: 0,
          selfGeneration: 0,
          selfGenerationMethod: 0,
          // districts: [],
          district: "",
          serviceProvider: "",
          monthlyBill: 0,
        });

        setTimeout(() => {
          this.setState({ isOpen: false });
        }, 2000);

        // window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({ loading: false });
        // setLoading(false);
        // setMessage(resMessage);
        alert(resMessage);
        this.setState({ isOpen: true });
      }
    );
  };
  render() {
    return (
      <div>
        <Header />

        {/* <!-- Page Content --> */}
        <div class="page-heading header-text">
          <div class="container">
            {/* <Modal
              isOpen={this.state.isOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
             
              <h2></h2>
              <button onClick={this.closeModal}>close</button>
              
            </Modal> */}
            <div class="row">
              <div class="col-md-12">
                <h1>Solar Power Systems</h1>
                <span style={{ color: "#66B032" }}>
                  Providing smart solutions for smart people
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="single-services ">
          <div class="container">
            <div class="row" id="tabs">
              <div class="col-md-4">
                <ul>
                  <li>
                    <a onClick={() => this.pressTab1()}>
                      Residential Solar Systems{" "}
                      <i class="fa fa-angle-right"></i>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.pressTab2()}>
                      Corporate Solar Systems<i class="fa fa-angle-right"></i>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.pressTab3()}>
                      Maintenance Of Solar Systems
                      <i class="fa fa-angle-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-8">
                <section class="tabs-content">
                  {this.state.tab1Value ? (
                    <article id="tabs-1">
                      <img
                        src="assets/images/image01.jpg"
                        alt=""
                        style={{ width: "100%", height: "80%" }}
                      />
                      <h4>Residential Solar Systems</h4>
                      <p>
                        We love to help corporates to reduce their monthly bills
                        by providing the best corporate solar panel for their
                        use. These are a lifetime investment and the best
                        solutions which they can have as a long term goal. A
                        company grows when they make wise decisions.
                        KandyanEnergy we are also a corporation which is
                        providing the best solar panel for every corporation
                        which is easy to install and maintain.
                        <br />
                        <br />
                        Best solar panels for commercial use If you are a
                        corporate owner you are at the right place to make your
                        investment and get a lifetime supported solar panel for
                        your company. We provide 100% uptime service and
                        maintenance for all our precious customers. Just
                        checkout our previous customers' feedback and our
                        previous installations on various corporate projects.
                      </p>
                    </article>
                  ) : null}
                  {this.state.tab2Value ? (
                    <article id="tabs-2">
                      <img
                        src="assets/images/image02.jpg"
                        alt=""
                        style={{ width: "100%", height: "80%" }}
                      />
                      <h4>Corporate Solar Systems</h4>
                      <p>
                        We love to help corporates to reduce their monthly bills
                        by providing the best corporate solar panel for their
                        use. These are a lifetime investment and the best
                        solutions which they can have as a long term goal. A
                        company grows when they make wise decisions.
                        KandyanEnergy we are also a corporation which is
                        providing the best solar panel for every corporation
                        which is easy to install and maintain.
                        <br />
                        <br />
                        Best solar panels for commercial use If you are a
                        corporate owner you are at the right place to make your
                        investment and get a lifetime supported solar panel for
                        your company. We provide 100% uptime service and
                        maintenance for all our precious customers. Just
                        checkout our previous customers' feedback and our
                        previous installations on various corporate projects.
                      </p>
                    </article>
                  ) : null}

                  {this.state.tab3Value ? (
                    <article id="tabs-3">
                      <img
                        src="assets/images/image03.jpg"
                        alt=""
                        style={{ width: "100%", height: "80%" }}
                      />
                      <h4>Maintenance Of Solar Systems</h4>
                      <p>
                        Mauris lobortis quam id dictum dignissim. Donec
                        pellentesque erat dolor, cursus dapibus turpis hendrerit
                        quis. Suspendisse at suscipit arcu. Nulla sed erat
                        lectus. Nulla facilisi. In sit amet neque sapien. Donec
                        scelerisque mi at gravida efficitur. Nunc lacinia a est
                        eu malesuada. Curabitur eleifend elit sapien, sed
                        pulvinar orci luctus eget.
                        <br />
                        <br />
                        Class aptent taciti sociosqu ad litora torquent per
                        conubia nostra, per inceptos himenaeos. Nunc vel
                        ultrices nulla, ac tincidunt eros. Aenean quis tellus
                        velit. Praesent pretium justo non auctor condimentum.
                      </p>
                    </article>
                  ) : null}
                </section>
              </div>
            </div>
          </div>
        </div>

        <div
          class="callback-form callback-services fun-facts"
          id={"solar-form"}
        >
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="section-heading">
                  <h2>
                    {" "}
                    <em>Get Your Solar System Now</em>
                  </h2>
                  <span>
                    “Looking to get powered by the sun? We got you covered.”
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="contact-form" style={{ position: "relative" }}>
                  <form
                    id="contact"
                    action=""
                    method="post"
                    onSubmit={this.onSubmittRequest}
                  >
                    {/* <div
                      style={{
                        position: "absolute",

                        // this will stretch overlay to fill width and height of form
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      }}
                    > */}

                    {/* </div> */}

                    <div class="row">
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input
                            name="fName"
                            type="text"
                            class="form-control"
                            id="fName"
                            placeholder="Full Name"
                            value={this.state.fNamae}
                            onChange={(e) =>
                              this.setState({ fNamae: e.target.value })
                            }
                          />
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input
                            name="lName"
                            type="text"
                            class="form-control"
                            id="lName"
                            placeholder="Address"
                            value={this.state.lName}
                            onChange={(e) =>
                              this.setState({ lName: e.target.value })
                            }
                          />
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input
                            name="email"
                            type="text"
                            class="form-control"
                            id="email"
                            required="true"
                            pattern="[^ @]*@[^ @]*"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input
                            name="mobile"
                            type="text"
                            class="form-control"
                            id="mobile"
                            required="true"
                            placeholder="Mobile:-07124567898"
                            value={this.state.mobile}
                            pattern="^([0]?[0-9]){9}$"
                            onChange={(e) =>
                              this.setState({ mobile: e.target.value })
                            }
                          />
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <select
                            class="form-control"
                            name="serviceProvider"
                            value={this.state.serviceProvider}
                            onChange={(e) =>
                              this.setState({ serviceProvider: e.target.value })
                            }
                            id="requestChoice"
                          >
                            <option value="default">
                              ---select service provider---
                            </option>
                            <option value="LECO">LECO</option>
                            <option value="CEB">CEB</option>
                          </select>
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input
                            name="regNo"
                            type="text"
                            class="form-control"
                            id="regNo"
                            pattern="^\d{10}$"
                            placeholder="LECO/CEB No."
                            value={this.state.regNo}
                            onChange={(e) =>
                              this.setState({ regNo: e.target.value.trim() })
                            }
                          />
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input
                            name="monthlyBill"
                            type="number"
                            class="form-control"
                            id="monthlyBill"
                            placeholder="Monthly Bill"
                            value={this.state.monthlyBill}
                            onChange={(e) =>
                              this.setState({ monthlyBill: e.target.value })
                            }
                            max={42500}
                          />
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <input
                            name="noOfUnits"
                            type="number"
                            class="form-control"
                            id="noOfUnits"
                            min={1}
                            placeholder="No of Units"
                            value={this.state.noOfUnits}
                            onChange={(e) =>
                              this.setState({ noOfUnits: e.target.value })
                            }
                          />
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <select
                            class="form-control"
                            name="requestChoice"
                            value={this.state.requestChoice}
                            onChange={(e) =>
                              this.setState({ requestChoice: e.target.value })
                            }
                            id="requestChoice"
                          >
                            <option value="default">
                              ---select one option---
                            </option>
                            <option value="Monthly bill need to be reducing around 0LKR with additional income">
                              Monthly bill need to be reducing around 0LKR.
                            </option>
                            <option value="Need to earn extra income">
                              Need to earn extra income
                            </option>
                            <option value="Need to earn extra income+Monthly bill need to be reducing around 0LKR.">
                              Need to earn extra income+Monthly bill need to be
                              reducing around 0LKR.
                            </option>
                          </select>
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <select
                            class="form-control"
                            name="district"
                            value={this.state.district}
                            onChange={(e) =>
                              this.setState({ district: e.target.value })
                            }
                            id="requestChoice"
                          >
                            <option value="default">
                              ---select your district---
                            </option>
                            {DistrctsList.map((item, index) => {
                              return (
                                <option value={item.id} key={item._id}>
                                  {item.name_en}
                                </option>
                              );
                            })}
                          </select>
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <select
                            class="form-control"
                            name="tarrif"
                            value={this.state.tarrif}
                            onChange={(e) =>
                              this.setState({ tarrif: e.target.value.trim() })
                            }
                            id="requestChoice"
                          >
                            <option value={0}>---select your tarrif---</option>
                            <option value={1}>Domestic(11,16)</option>
                            <option value={2}>Industrial(21,22,26,27)</option>
                            <option value={3}>General(31,32,36,37)</option>
                            <option value={4}>Goverment(33,34,38,39)</option>
                            <option value={5}>Hotel(41,46)</option>
                            <option value={6}>Religious(51,56)</option>
                          </select>
                        </fieldset>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <fieldset>
                          <select
                            class="form-control"
                            name="selfGeneration"
                            value={this.state.selfGeneration}
                            onChange={(e) =>
                              this.setState({
                                selfGeneration: e.target.value.trim(),
                              })
                            }
                            id="selfGeneration"
                          >
                            <option value={0}>---Self Generation---</option>
                            <option value={1}>Net Metering</option>
                            <option value={2}>Net Accounting</option>
                          </select>
                        </fieldset>
                      </div>

                      <div class="col-lg-12">
                        <fieldset>
                          <button
                            type="submit"
                            id="form-submit"
                            class="border-button"
                          >
                            Submitt
                          </button>
                        </fieldset>
                      </div>
                    </div>
                    <br />
                  </form>
                  {this.state.loading ? (
                    <Spinner
                      text="Please wait while we are processing your data"
                      show={true}
                    />
                  ) : this.state.isOpen ? (
                    <Spinner text={resMsg} show={false} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <iframe
          src="https://cdn.lightwidget.com/widgets/0c16f19afb0c56468bd72cbb47f42497.html"
          scrolling="no"
          allowTransparency="true"
          className="lightwidget-widget"
          style={{ width: "100%", border: 0, overflow: "hidden" }}
        />
        <WAFloating />
        <Footer />
      </div>
    );
  }
}
export default Services;
