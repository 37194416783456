import React, { Component } from "react";
import Header from "../Components/Hader";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaSolarPanel } from "react-icons/fa";
import WAFloating from "../Components/Common/WhatsAppBtn/FloatingBtn";
import Fullpage, {
  FullPageSections,
  FullpageSection,
} from "@ap.cx/react-fullpage";
import Pdf from "./KES-Company profile.pdf";

class About extends React.Component {
  render() {
    return (
      <div>
        <Header />

        {/* <!-- Page Content --> */}

        <div class="page-heading header-text">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h1>About Kandyan Energy</h1>
                <span style={{ color: "#66B032" }}>
                  "WE MAKE YOUR IDEAS A REALITY"
                </span>
              </div>
            </div>
          </div>
        </div>

        {/*================================ start company message ==============================*/}

        <div class="more-info about-info">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="more-info-content">
                  <div class="row">
                    <div class="col-md-6 align-self-center">
                      <div class="right-content">
                        <p style={{ color: "#fff", fontSize: 12 }}>
                          WHAT WE DO
                        </p>
                        <h2>
                          {" "}
                          <em style={{ color: "#fff" }}>
                            Providing Smart Solutions For Smart People
                          </em>
                        </h2>
                        <h6 style={{ color: "#66B032" }}>
                          Energize The Future
                        </h6>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="left-image">
                        <div class="right-content">
                          <p style={{ color: "#fff", fontSize: 15 }}>
                            As one of the best solar energy companies,
                            KandyanEnergy, we are committed to providing smart
                            solutions for smart people who think solar energy is
                            the best investment for their home, institute,
                            company, garment, and another place where they can
                            save money to reinvest in something else. As the
                            best and cost-effective company, we are ever ready
                            to provide our intelligence solutions for anyone who
                            seeks our company for their own unit of the solar
                            panel.{" "}
                          </p>
                          <p style={{ color: "#fff", fontSize: 15 }}>
                            <img
                              src="assets/images/sig-w.png"
                              alt=""
                              style={{ width: "auto", height: 55 }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*================================ end company message ==============================*/}
        {/*================================ start company profile ==============================*/}

        <div class="more-info-profile about-info-profile">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="more-info-content-profile">
                  <div class="row align-self-center">
                    <div class="left-image">
                      <div class="right-content">
                        <p style={{ color: "#fff", fontSize: 12 }}>
                          PROJECTS FOR WORLD
                        </p>
                        <h2>
                          {" "}
                          <em
                            style={{
                              color: "#fff",
                              alignItems: "center",
                            }}
                          >
                            Actions That Matters
                          </em>
                        </h2>
                        <p style={{ color: "#fff", fontSize: 15 }}>
                          We got all types of services for solar panel
                          installations like hybrid systems and Lithium-ion
                          Battery Backup Plans for an affordable rate. You can
                          choose the best from our previous works by viewing the
                          projects. Whatever you get from us is 100% quality and
                          guaranteed for the best service.
                        </p>
                        <p style={{ color: "#fff", fontSize: 15 }}>
                          {" "}
                          Hybrid Solar Systems <br></br>Lithium-ion Battery
                          Backup
                        </p>
                        {/* <Link
                          // to="./KES-Company-profile.pdf"
                          class="filled-button-powerplan"
                        > */}

                        <a
                          class="filled-button-powerplan"
                          href={Pdf}
                          target="_blank"
                        >
                          {" "}
                          download profile
                        </a>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*================================ end company profile ==============================*/}

        {/*================================ start our vission==============================*/}

        <div class="more-info-vission about-info-visson">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="more-info-content-vission">
                  <div class="row align-self-center">
                    <div class="left-image">
                      <div class="right-content">
                        <h2>
                          {" "}
                          <em
                            style={{
                              color: "#fff",
                              alignItems: "center",
                            }}
                          >
                            OUR VISSION
                          </em>
                        </h2>
                        <p style={{ color: "#fff", fontSize: 15 }}>
                          To be a strong corporate entity Leading the field in
                          value – added Engineering services from Sri Lanka
                          Whilst ensuring that our name would be trusted By each
                          and every one Of our customers all over the country
                          and the global commercial spectrum And That our
                          superior products/services would be a Brilliant name
                          In the Engineering arena..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*================================ end our vission ==============================*/}

        {/*================================ start our mission==============================*/}

        <div
          class="more-info-vission about-info-visson"
          style={{ backgroundColor: "#0E1D05" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="more-info-content-vission">
                  <div class="row align-self-center ">
                    <div class="left-image">
                      <div class="right-content ">
                        <h2>
                          {" "}
                          <em
                            style={{
                              color: "#fff",
                              alignItems: "center",
                            }}
                          >
                            OUR MISSION
                          </em>
                        </h2>
                        <p style={{ color: "#fff", fontSize: 15 }}>
                          To make clean energy available to homeowners,
                          businesses or other institutions/organizations at a
                          lower cost than they pay for energy To be a strong
                          corporate entity Leading the field in value
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*================================ end our mission ==============================*/}

        {/* ===============================start Time line================================= */}

        <div class="fun-facts" style={{ marginTop: 0, height: "auto" }}>
          <div class="container" style={{ alignItems: "center" }}>
            <div class="text-area">
              <p>WHO ARE WE</p>
              <h2>Kandyan Energy Company History</h2>
              <h6>For Controling Your Energy Production</h6>
            </div>
            <div class="row" style={{ alignItems: "center" }}>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#66b032",
                    color: "#fff",
                  }}
                  contentArrowStyle={{
                    borderRight: "5px solid  #66b032",
                  }}
                  // date="2008 - present"
                  iconStyle={{ background: "#66b032", color: "#fff" }}
                  icon={<FaSolarPanel />}
                >
                  <h3 className="vertical-timeline-element-title">
                    Company founded
                  </h3>
                  <p>
                    Edison’s which was established in late 2008 as a
                    Proprietorship of importing & selling computers,
                    Accessories, Consultancy of Information Technologies such as
                    All kind of Network designing and contracting business
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#66b032",
                    color: "#fff",
                  }}
                  contentArrowStyle={{
                    borderRight: "5px solid  #66b032",
                  }}
                  // date="2010 - present"
                  iconStyle={{ background: "#66b032", color: "#fff" }}
                  icon={<FaSolarPanel />}
                >
                  <h3 className="vertical-timeline-element-title">
                    Features & add-ons
                  </h3>
                  <p>
                    After having continuous work experience since 2008 in the
                    field of System Engineering, was upgraded to large scale
                    contacting & consultancy service in January 2010
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#66b032",
                    color: "#fff",
                  }}
                  contentArrowStyle={{
                    borderRight: "5px solid  #66b032",
                  }}
                  // date="2015 - present"
                  iconStyle={{ background: "#66b032", color: "#fff" }}
                  icon={<FaSolarPanel />}
                >
                  <h3 className="vertical-timeline-element-title">
                    continue to inspire
                  </h3>
                  <p>
                    We expanded to Edison’s Greenspring Energy (Pvt) Ltd/EDG
                    Energy (Pvt) Ltd.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#66b032",
                    color: "#fff",
                  }}
                  contentArrowStyle={{
                    borderRight: "5px solid  #66b032",
                  }}
                  // date="2020 - present"
                  iconStyle={{ background: "#66b032", color: "#fff" }}
                  icon={<FaSolarPanel />}
                >
                  <h3 className="vertical-timeline-element-title">
                    continue to inspire
                  </h3>
                  <p>
                    We re-branded our company with the name "Kandyan Energy
                    Solutions (Pvt) Ltd"
                  </p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          </div>
        </div>

        {/* ===============================end Time line================================= */}

        {/*================================ start our animation==============================*/}

        <div
          class="more-info-vission about-info-visson"
          style={{ backgroundColor: "#0E1D05", marginTop: 0 }}
        >
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <div class="">
                  <div className="count-digit-count">
                    <span class="count-digit">8</span>+ YEARS
                  </div>
                  <div class="count-title">Of Experience & Record</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="">
                  <div className="count-digit-count">
                    <span class="count-digit">250</span>+
                  </div>
                  <div class="count-title">Systems Installed</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="">
                  <div className="count-digit-count">
                    <span class="count-digit">1600</span>kWh
                  </div>
                  <div class="count-title">Energy Financing Done</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="">
                  <div className="count-digit-count">
                    <span class="count-digit">600</span>+
                  </div>
                  <div class="count-title">Hours Of Inspection</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*================================ end our animation ==============================*/}

        <iframe
          src="https://cdn.lightwidget.com/widgets/0c16f19afb0c56468bd72cbb47f42497.html"
          scrolling="no"
          allowTransparency="true"
          className="lightwidget-widget"
          style={{ width: "100%", border: 0, overflow: "hidden" }}
        />

        <WAFloating />
        <Footer />
      </div>
    );
  }
}
export default About;
