import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Header from "../Components/Hader";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import { TEMPLATE_ID, USER_ID } from "../email";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaSolarPanel } from "react-icons/fa";
import LogoSlider from "../Components/LogoSlider";
import WAFloating from "../Components/Common/WhatsAppBtn/FloatingBtn";
import { OnModalContext } from "../context";
import MapModal from "../Components/Common/MapModal";

const useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () => elRef.current.scrollIntoView();

  return [executeScroll, elRef];
};

const Home = () => {
  const { onModal, changeModalClick } = useContext(OnModalContext);

  // const [homeFlag, setHomeFlag] = useState(false);

  // setHomeFlag(true);

  // useEffect(() => {
  //   if (homeFlag) {
  //     // window.location.reload();
  //     setHomeFlag(false);
  //   }
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs.sendForm(`service_n7s41nj`, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        alert("Message Sent, We will get back to you shortly", result.text);
      },
      (error) => {
        alert("An error occurred, Please try again", error.text);
      }
    );
  };

  // useEffect(() => {
  //   window.onload = function () {
  //     if (!window.location.hash) {
  //       window.location = window.location + "#loaded";
  //       window.location.reload();
  //     }
  //   };
  // }, []);

  return (
    <div onClick={changeModalClick}>
      {/* <!-- ***** Preloader Start ***** --> */}
      {/* <div id="preloader">
        <div class="jumper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> */}
      {/* <!-- ***** Preloader End ***** --> */}

      {/* <!-- Header --> */}
      <Header />
      {onModal ? <MapModal /> : null}

      {/* <!-- Page Content -->
       <!-- Banner Starts Here --> */}
      <div className="section">
        <div class="main-banner header-text" id="top">
          <div class="Modern-Slider">
            {/* <!-- Item --> */}
            <div class="item item-1">
              <div class="img-fill">
                <div class="text-content">
                  <h6 style={{ fontSize: "35px", color: "#0ef719" }}>
                    අදම ඔබේ සූර්‍ය බලශක්ති සැලැස්ම තෝරාගන්න!
                  </h6>
                  <h6 style={{ color: "white", fontSize: "25px" }}>
                    අපගේ පෝරමය පුරවා ඔබේ සූර්‍ය ව්‍යාපෘති යෝජනාව සාදාගන්න.
                  </h6>

                  <p style={{ fontSize: "18px" }}>
                    අප ශ්‍රී ලංකාවේ ප්‍රමුඛතම සූර්‍ය බලශක්ති සමාගමකි.
                    <br />
                    ගුණාත්මක හා විශ්වසනීය සේවාවක් ලබාගැනීමට අප හා සම්බන්ඳවන්න.
                  </p>

                  <Link
                    to="/Contact"
                    class="filled-button"
                    style={{ marginBottom: 7 }}
                  >
                    contact us
                  </Link>
                  <a
                    href="/Services#solar-form"
                    class="filled-button-powerplan"
                  >
                    choose power plan
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- // Item -->
          <!-- Item --> */}
            <div class="item item-2">
              <div class="img-fill">
                <div class="text-content">
                  <h6 style={{ color: "#0ef719" }}>
                    Welcome to Kandyan Energy Solutions
                  </h6>
                  <h4>Energize the Future</h4>
                  <p>
                    We provide the best On-grid and Off-grid solar energy
                    solutions for your home or workplace. This is Your Turn to
                    Go Solar.{" "}
                  </p>
                  <Link
                    to="/Services"
                    class="filled-button"
                    style={{ marginBottom: 7 }}
                  >
                    Our Services
                  </Link>
                  <a
                    href="/Services#solar-form"
                    class="filled-button-powerplan"
                  >
                    choose power plan
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- // Item -->
          <!-- Item --> */}
            {/* <div class="item item-3">
            <div class="img-fill">
              <div class="text-content">
                <h6>we are ready to collaborate with you</h6>
                <h4>Digital<br /> Marketing</h4>
                <p>VERO can make a partnership with you to provide top level markerting support. We can provide beneficial packages to you for social media campaigns and brand markerting. Contact Us for more details...</p>
                <Link to="/About" class="filled-button">Learn More</Link>
              </div>
            </div>
          </div> */}
            {/* <!-- // Item --> */}
          </div>
        </div>
      </div>

      {/* <!-- Banner Ends Here --> */}

      {/* ===============================power plan navigator start================================= */}
      <div className="section">
        <div
          class="more-info-vission about-info-visson"
          style={{
            backgroundColor: "#66b032",
            marginTop: -5,
            height: "14rem",
          }}
        >
          <div class="container-fluid">
            <div className="row">
              <div className="col-sm-6 align-self-center">
                <div className="power-plan-text">
                  <h2 style={{ color: "#000" }}>Get Your Solar System Now!</h2>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: 15,
                      textAlign: "left",
                    }}
                  >
                    Create your project proposal by fillingout our form.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="power-plan-button">
                  <a
                    href="/Services#solar-form"
                    class="filled-button-powerplan"
                    style={{ backgroundColor: "#000", border: "#fff" }}
                  >
                    choose power plan
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===============================power plan navigator end================================= */}

      {/* ==================Integrated Solar Solutions================== */}
      <div className="section">
        <div class="more-info">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="more-info-content-home">
                  <div class="row">
                    <div class="col-md-8 align-self-center">
                      <div class="right-content">
                        <h6 style={{ color: "#fff" }}>WHO ARE WE </h6>
                        <h2>
                          {" "}
                          <em style={{ color: "#66b032" }}>
                            Integrated Solar Solutions
                          </em>
                        </h2>
                        <p style={{ color: "#fff", fontSize: 15 }}>
                          Solar Energy is the new normal for everywhere
                          KandyanEnergy we are committed to providing
                          high-quality solar energy systems for everyone who is
                          willing to get the power from the sun to their own
                          place. We are one of the leading solar energy
                          companies in Sri Lanka and the best from Kandy. Our
                          mission is to bring strength and authority to reduce
                          cost and get used to solar energy by ridiculing them
                          the best solar energy. If you are searching for the
                          best solar energy companies we stand in the front row
                          because we have won many hearts by providing quality
                          services for everyone. <br></br>
                          Our true commitments give us more priorities to get
                          more leads and make the best solutions for large-scale
                          companies, hotels, commercial places, and schools to
                          get their own solar panel to save more power and get
                          used to solar energy. If you are searching for a
                          conclusion of solar energy then there's nothing that
                          stands rather than KandyanEnergy.
                        </p>
                        <Link to="/Contact" class="filled-button">
                          contact us
                        </Link>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="left-image-home">
                        <img src="assets/images/office.jpeg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================Integrated Solar Solutions end ================== */}

      {/* <div class="services">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-heading">
                <h2>
                  {" "}
                  <em>Our Services</em>
                </h2>
                <span style={{ color: "#fff" }}>
                  "We make your ideas a reality"
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item">
                <img src="assets/images/service_01.jpg" alt="" />
                <div class="down-content">
                  <h4 style={{ color: "#04af68" }}> Software Consulting</h4>
                  <p style={{ color: "#fff" }}>
                    If you are new to business, and you don't have a proper plan
                    of how to apply the correct software methodology into your
                    business ,we can suport to achieve your success. We will
                    navigate you to your dream.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item">
                <img src="assets/images/service_02.jpg" alt="" />
                <div class="down-content">
                  <h4>Software Solutions</h4>
                  <p>
                    We, at VERO IT provide with ERP systems, E-Commerce and
                    other types of Application Softwares for you. We have a
                    Research and Development team to address social problems
                    with effective software support
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item">
                <img src="assets/images/service_03.jpg" alt="" />
                <div class="down-content">
                  <h4>Digital Marketing</h4>
                  <p>
                    Our team is specialized for Brand Marketing, Product
                    Marketing and Personal Marketing. We have an innovative crew
                    to help you any time and provide a good service in digital
                    marketing{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* ===============================start Time line================================= */}

      <div class="fun-facts">
        <div class="container" style={{ alignItems: "center" }}>
          <div class="text-area">
            <p>WHAT WE DO</p>
            <h2>Energizing Sri Lanka With Solar Power</h2>
            <h6>The Leading Residential & Corporate Solar Solution Provider</h6>
          </div>
          <div class="row" style={{ alignItems: "center" }}>
            <VerticalTimeline>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#66b032",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "5px solid  #66b032",
                }}
                // date="2011 - present"
                iconStyle={{ background: "#66b032", color: "#fff" }}
                icon={<FaSolarPanel />}
              >
                <h3 className="vertical-timeline-element-title">
                  Solar Systems Installation
                </h3>
                <p
                  className="vertical-timeline-element-subtitle"
                  style={{ color: "#000" }}
                >
                  WITH MOST SUNLIGHT CONVERSION EFFICIENCY
                </p>
                <p>
                  If you think you got the best places for solar energy
                  installations? then we are ready to install our high-quality
                  solar panels at your place with a lifetime guarantee. We got
                  the best technicians and electricians in Sri Lanka!
                </p>
                <fieldset>
                  {/* <button type="submit" id="form-submit" class="border-button">
                    Power Plan
                  </button> */}
                  <Link to="/Services" class="border-button">
                    Power Plan
                  </Link>
                </fieldset>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#66b032",
                  color: "#fff",
                  // marginTop: -60,
                }}
                contentArrowStyle={{
                  borderRight: "5px solid  #66b032",
                }}
                // date="2011 - present"
                iconStyle={{
                  background: "#66b032",
                  color: "#fff",
                  // marginTop: -60,
                }}
                icon={<FaSolarPanel />}
              >
                <h3 className="vertical-timeline-element-title">
                  The Preventative Maintenance
                </h3>
                <p
                  className="vertical-timeline-element-subtitle"
                  style={{ color: "#000" }}
                >
                  INSPECTION TO PREVENT EMERGENCY REPAIR
                </p>
                <p>
                  For the very first time KandyanEnergy we are ready to maintain
                  your solar panel at your own place. Our skilled technicians
                  are ready to make the maintenance works with 100%
                  satisfactions.
                </p>
                <fieldset>
                  <Link to="/Services" class="border-button">
                    Power Plan
                  </Link>
                </fieldset>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#66b032",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "5px solid  #66b032",
                }}
                // date="2011 - present"
                iconStyle={{ background: "#66b032", color: "#fff" }}
                icon={<FaSolarPanel />}
              >
                <h3 className="vertical-timeline-element-title">
                  Upgrade Or Replacement
                </h3>
                <p
                  className="vertical-timeline-element-subtitle"
                  style={{ color: "#000" }}
                >
                  GET YOUR PANELS IN GOOD SHAPE REGULARLY
                </p>
                <p>
                  Our skilled workers are ready to upgrade or replace your
                  current solar panel. We are always concerned about the quality
                  and we got the best solar energy stocks to buy and a 100%
                  profitable investment for your lifetime.
                </p>
                <fieldset>
                  <Link to="/Services" class="border-button">
                    Power Plan
                  </Link>
                </fieldset>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </div>
      </div>
      {/* ===============================end Time line================================= */}

      {/* =============================start green map================================== */}

      {/* ==============================end green map==================================== */}

      {/* ===============================start map================================= */}

      {/* <div id="map">
        <iframe
          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=330,1st Lane,Bopettha,Gothatuwa&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          width="100%"
          height="1000px"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div> */}

      {/* ===============================end map================================= */}

      {/* ===============================logo slider start================================= */}
      <div
        class="more-info-vission about-info-visson"
        style={{ backgroundColor: "#fff", marginTop: 5 }}
      >
        <div class="container-fluid">
          <LogoSlider />
        </div>
      </div>

      {/* ===============================logo slider end================================= */}
      <iframe
        src="https://cdn.lightwidget.com/widgets/0c16f19afb0c56468bd72cbb47f42497.html"
        scrolling="no"
        allowTransparency="true"
        className="lightwidget-widget"
        style={{ width: "100%", border: 0, overflow: "hidden" }}
      />

      <WAFloating />
      <Footer />
    </div>
  );
};
export default Home;
