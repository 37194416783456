
import {axiosInstance} from './AxiosInstance';

const API_URL="/api/bookings";

//Request resedential Power Plan request


const resedentialPowerPlanRequest = async(data) => {

    try{
      const response = await axiosInstance.post(API_URL + "/add-booking",data);
  
       return response.data;
  
    }catch(err){
      throw err;
    }
  
  
      
  };

  export default {resedentialPowerPlanRequest};