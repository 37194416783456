import React, { Component } from "react";
import emailjs from "emailjs-com";
import { TEMPLATE_ID, USER_ID } from "../email";

const Footer = () => {
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs.sendForm(`service_n7s41nj`, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        alert("Message Sent, We will get back to you shortly");
      },
      (error) => {
        alert("An error occurred, Please try again", error.text);
      }
    );
  };

  return (
    <div>
      {/* <!-- Footer Starts Here --> */}
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-md-5 footer-item">
              <h4>Contact Us</h4>
              <ul class="menu-list">
                <li>
                  <a>
                    Visit The Office
                    <br></br>
                    No 645, William Gopallawa Mawatha, Kandy
                    {/* <br></br>
                    Mulgampola. Kandy. */}
                  </a>
                </li>
                <li>
                  <a>
                    Phone Inquiry<br></br> +94 81 220 8108
                  </a>
                </li>
                <li>
                  <a>
                    Send Email<br></br> info@kandyanenergy.com
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-5 footer-item">
              <h4>Additional Pages</h4>
              <ul class="menu-list">
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">How We Work</a>
                </li>
                <li>
                  <a href="#">Quick Support</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div class="col-md-2 footer-item last-item">
              <h4>Follow Us</h4>
              <ul class="social-icons">
                <li>
                  <a
                    rel="nofollow"
                    href="http://www.facebook.com/kandyanenergy"
                    target="_blank"
                  >
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                {/* <li>
                  <a href="#">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/vero-it-solutions/">
                    <i class="fa fa-linkedin"></i>
                  </a>
                </li> */}
                <li>
                  <a href="http://instagram.com/kandyanenergy">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <div class="sub-footer">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <p>
                Copyright &copy;{" "}
                <span style={{ color: "#66b032" }}>Kandy Energy-2022</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
